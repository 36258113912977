<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-07 17:26:25
 * @LastEditors: hutian
 * @LastEditTime: 2021-05-17 10:04:43
-->
<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
            <a-form-model-item label="门店" prop="storeId" :autoLink="false" ref="test">
                <a-select placeholder="请选择" v-model="rowData.storeId" @change="onStoreChange">
                    <a-select-option :value="item.id" v-for="(item, index) in storeList" :key="index">{{item.name}}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="员工姓名" prop="managerId">
                <a-select placeholder="请选择" v-model="rowData.managerId" >
                    <a-select-option :value="item.id" v-for="(item, index) in managerList" :key="index">{{item.name}}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="目标(万元)" prop="targetAmount">
                <a-input-number v-model="rowData.targetAmount" placeholder="目标金额"></a-input-number>
            </a-form-model-item>
            <a-form-model-item label="绩效时间" prop="targetDate" :autoLink="false" ref="tesxt">
                <a-month-picker v-model="rowData.targetDate" :format="monthFormat" @change="onDete" />
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editDealerAchievements, selectByIdDealerAchievements, addDealerAchievements } from '../api/DealerAchievementsApi'
import moment from 'moment'

export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                storeId: [
                    { required: true, message: '请选择门店', trigger: 'change' }
                ],
                managerId: [
                    { required: true, message: '请选择员工', trigger: 'change' }
                ],
                targetAmount: [
                    { required: true, message: '请输入目标金额', trigger: 'blur' }
                ],
                targetDate: [
                    { required: true, message: '请选择绩效时间', trigger: 'change' }
                ],
            },
            managerList: [],
            storeList: [],
            monthFormat: 'YYYY-MM',
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = {}
            this.managerList = []

            if(handle == 'edit') {
                selectByIdDealerAchievements(row.id).then(res => {
                    this.rowData = res.body

                    this.getManager(res.body.storeId)
                    
                    let date = res.body.targetDate
                    this.rowData.targetDate = moment(date)
                })
            }
            
            this.axios.get(`/api/base/achievements/dealerAchievements/store/choose?pageNumber=1&pageSize=9999`).then(res => { // 门店
                this.storeList = res.body.records
            })

        },
        
        // 获取员工
        getManager(id) {
            this.axios.get(`/api/base/achievements/dealerAchievements/manager/choose?storeId=${id}`).then(res => {
                this.managerList = res.body
            })
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }

                // let date = this.rowData.targetDate
                // this.rowData.targetDate = date.format(this.monthFormat)

                const res = this.handle === 'add' ? await addDealerAchievements(this.rowData) : await editDealerAchievements(this.rowData)
                
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        },

        onStoreChange(val) {
            this.$refs.test.onFieldChange() 
            this.getManager(val)
        },

        onDete(value) {
            this.$refs.tesxt.onFieldChange() 
        },

    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
