/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-07 17:26:25
 * @LastEditors: hutian
 * @LastEditTime: 2021-05-07 17:40:53
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerAchievements = params => axios({
    url: '/api/base/achievements/dealerAchievements/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerAchievements = params => axios({
    url: '/api/base/achievements/dealerAchievements/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerAchievements = params => axios({
    url: '/api/base/achievements/dealerAchievements/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerAchievements = params => axios({
    url:'/api/base/achievements/dealerAchievements/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerAchievements = params => axios({
    url: '/api/base/achievements/dealerAchievements/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
